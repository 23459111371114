.card-price {
	display: inline-block;
  
  width: auto;
	height: 38px;
	
	background-color: #3F51B5;
	-webkit-border-radius: 3px 4px 4px 3px;
	-moz-border-radius: 3px 4px 4px 3px;
	border-radius: 3px 4px 4px 3px;
	
	border-left: 1px solid #3F51B5;

	/* This makes room for the triangle */
	margin-left: 19px;
	
	position: relative;
	
	color: white;
	font-weight: 300;
	font-size: 22px;
	line-height: 38px;

	padding: 0 10px 0 10px;
}

/* Makes the triangle */
.card-price:before {
	content: "";
	position: absolute;
	display: block;
	left: -19px;
	width: 0;
	height: 0;
	border-top: 19px solid transparent;
	border-bottom: 19px solid transparent;
	border-right: 19px solid #3F51B5;
}

/* Makes the circle */
.card-price:after {
	content: "";
	background-color: white;
	border-radius: 50%;
	width: 4px;
	height: 4px;
	display: block;
	position: absolute;
	left: -9px;
	top: 17px;
}


.price {
    display: inline-block;
    width: auto;
    height: 38px;
    background-color: #3F51B5;
    border-radius: 3px 4px 4px 3px;
    border-left: 1px solid #3F51B5;
    margin-left: 19px;
    position: relative;
    color: white;
    font-weight: 300;
    font-size: 22px;
    line-height: 38px;
    padding: 0 10px 0 10px;
}

.gradient-wrapper { 
	padding: 15px; 
	border-radius: 50%;
	background: -webkit-linear-gradient( crimson 0%, #f90 100%);


  }
  
#maincircle { width: 64px; height: 64px; background: #424242; border-radius: 50%; }

.avatar {
    width:128px;
    border:2px solid red;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}

.gavatar {
    width:128px;
    border:2px solid green;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}

