@font-face{
    font-family: 'NunItalic';
    src: url(../../fonts/NunitoSans-Italic.ttf);
}


.sliderPriceText{
    font-family: 'NunItalic';
    font-size: 12px;
    margin-bottom:0.5rem;
}