.yourFeedbackTitle {
    font-size: 16px;
    font-family: 'ManRegular';
    color: #566a7a;
}

.willPayColor {
    color: rgb(84, 165, 56);
    font-family: 'ManMedium';
}

.willNotColor {
    color: rgb(184, 75, 75);
    font-family: 'ManMedium';
}