@font-face{
    font-family:'ManSemi';
    src:url(../../fonts/Manrope-SemiBold.ttf);
}
@font-face{
    font-family:'ManLight';
    src:url(../../fonts/Manrope-Light.ttf);
}
@font-face{
    font-family:'ManMedium';
    src:url(../../fonts/Manrope-Medium.ttf);
}
@font-face{
    font-family:'ManRegular';
    src:url(../../fonts/Manrope-Regular.ttf);
}


.sidecolor{    
    background: #ECECEC;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card{
    padding: 40px;
    height: 320px;
    margin-left: auto;
    margin-top: 60px;
    margin-right: auto;
    font-size:22px;
    font-family: 'ManLight';
    border:0 !important;
}

.cardThankYou{
    width: 768px;
    height: auto;
    margin-left: auto;
    margin-top: 60px;
    margin-right: auto;
    font-size:22px;
    font-family: 'ManLight';
}

.card-home{
    font-family: 'ManMedium';
    font-size:1rem;
    padding:15px;
}

.card-home-text{
    font-family:'ManMedium';
    font-size:24px;
    padding:15px;
    color:#1364a2;
}

.regular{
    background-color:#6a7bff;
    color:#ffffff;
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border:0;
    width: 130px;
    height: 40px;   
    margin-right:auto;
}

.homeInput{
    width:390px;
    box-shadow:inset 1px 2px 3px rgba(0, 0, 0, 0.25);
    border:0;
    font-size: 14px;
    background-color: #ECECEC;
    padding-left: 5px;
}

.thankYouLinkWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px;
    border-radius: 5px;
    margin: 20px 0px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 -4px 24px 2px rgb(0 0 0 / 3%);
    line-height: 1.5;
    cursor: pointer;
    width:90%;
    max-width: 700px;
    margin-left: auto;
    margin-right:auto;
}

.thankYouDesc{
    display:flex;
    flex-direction: column;
}

.thankYouTitle{
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;
}

.thankYouHeader{
    color: #1364a2;
    font-weight: 600;
    font-size: 15px;
}

.thankYouBody{
    font-size:14px;
    text-align:left;
}

.thankYouTitleDot{
    font-family: 'ManRegular';
}

.thankYouImage{
    display:flex;
    align-content: center;
    align-items:center;
    height:100%;
}

.thankYouImage>img{
    max-height: 64px;
    object-fit:cover;
}

.validation{
    margin-left: auto;
    margin-right: auto;
    width:518px;
    font-size: 14px;
    padding-top: 5px;
    color:red;
}

.india{
    font-family:'ManRegular';
    padding-top:10px;
    font-size:14px;
}

.footer{
    font-size:14px;
    /* position: absolute;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
    overflow: hidden; */
}

.thankYouSubtitle{
    font-size:16px;
    font-family:'ManRegular';
}

.feedCard{
    font-family: 'ManRegular';
}

.feedProfile{
    height:60px;
    border-radius:30px;
    margin-right:20px;
}

.feedDesc{
    font-family:'ManLight';
}

.tags{
    background-color: #efefef;
    font-family:'ManRegular';
    font-size:12px;
    padding:8px;
    margin-right:12px;
}

.willpay{
    background-color:#41A345;
    color:#ffffff;
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border:0;
    width: 180px;
    height: 40px;   
}

.willnot{
    background-color:#DB6D7A;
    color:#ffffff;
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border:0;
    width: 180px;
    height: 40px;   
}

.cardLinks{
    color: #6a7bff;
    font-family: 'ManRegular';
    font-size: 14px;
    text-decoration: underline;
}

.landingCard{
    border:0;
    box-shadow: 0px 0px 10px #d0d0d0;
}

.goBack{
    font-size:20px;
    font-family: 'ManRegular';
    text-decoration: underline;
    cursor:pointer;
    margin-top:10px;
}


.loginInput{
    box-shadow:inset 1px 2px 3px rgba(0, 0, 0, 0.25);
    border:0;
    font-size: 14px;
    background-color: #ECECEC;
    padding-left: 5px;
}

.twitterButton{
    padding: 8px 18px;
    background: #ffffff;
    border: 0;
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    font-size:14px;
    font-family:'ManMedium';
}

@media (min-width:768px){
    .landingCard{
        width: 30rem; 
    }
}