@font-face{
    font-family:'ManSemi';
    src:url(../fonts/Manrope-SemiBold.ttf);
}
@font-face{
    font-family:'ManLight';
    src:url(../fonts/Manrope-Light.ttf);
}
@font-face{
    font-family:'ManMedium';
    src:url(../fonts/Manrope-Medium.ttf);
}
@font-face{
    font-family:'ManRegular';
    src:url(../fonts/Manrope-Regular.ttf);
}

.title{
    font-family: 'ManSemi';
    font-size:18px;
    margin-bottom: 0;
}

.amount{
    font-family:'ManLight';
    font-size:16px;
}

.percentage{
    font-family: 'ManSemi';
    color:#df0000;
}

.percentageUp{
    font-family: 'ManSemi';
    color:#067304;
}

.graph{
    font-family: 'ManSemi';
    font-size:14px;
}

.tabbed{
    margin-bottom:1.5rem;
    font-family: 'ManSemi';
}
