@font-face {
    font-family: 'ManSemi';
    src: url(../../fonts/Manrope-SemiBold.ttf);
}

@font-face {
    font-family: 'ManLight';
    src: url(../../fonts/Manrope-Light.ttf);
}

@font-face {
    font-family: 'ManMedium';
    src: url(../../fonts/Manrope-Medium.ttf);
}

@font-face {
    font-family: 'ManRegular';
    src: url(../../fonts/Manrope-Regular.ttf);
}

.createInput{
    box-shadow:inset 1px 2px 3px rgba(0, 0, 0, 0.25);
    border:0;
    border-radius: 0;
    font-size: 12px;
    font-family: 'ManRegular';
    background-color: #ECECEC;
    padding-left: 5px;
    width:80%;
}

.createTextArea{
    box-shadow:inset 1px 2px 3px rgba(0, 0, 0, 0.25);
    border:0;
    border-radius: 0;
    font-size: 12px;
    font-family: 'ManRegular';
    background-color: #ECECEC;
    padding-left: 5px;
    width:100%;
}

.createdMessage{
    margin-left: auto;
    margin-top: 60px;
    margin-right: auto;
    font-size:22px;
    padding:60px;
    text-align:center;
    font-family: 'ManLight';
}


.profileCard {
    width: 30rem;
}

@media screen and (max-width: 1280px) {
    .profileCard {
        width: 30rem;
    }
    .grid{
        width:100%;
    }
}


@media screen and (max-width: 414px) {
    .profileCard {
        width: 100%;
    }
    .grid{
        width:100%;
    }
}

@media screen and (max-width: 375px) {
    .profileCard {
        width: 100%;
    }
    .grid{
        width:100%;
    }
}

@media screen and (max-width: 360px) {
    .profileCard {
        width: 100%;
    }
    .grid{
        width:100%;
    }
}

@media screen and (max-width: 320px) {
    .profileCard {
        width: 100%;
    }
    .grid{
        width:100%;
    }
}