
@font-face{
    font-family:'NunitoSemi';
    src:url(../../fonts/NunitoSans-SemiBold.ttf);
}
@font-face{
    font-family:'NunitoLight';
    src:url(../../fonts/NunitoSans-Light.ttf);
}
@font-face{
    font-family:'NunitoBold';
    src:url(../../fonts/NunitoSans-Bold.ttf);
}

.react-calendar{
    font-family:'NunitoSemi';
    border:0;
}

.react-calendar__tile--active{
    background-color:#6a7bff;
}

.react-calendar__tile--active:hover{
    background-color:#6a7bff;
}

.overlay {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
  }
  
  .overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  
  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
  }
  
  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }