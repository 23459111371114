@font-face {
    font-family: 'ManSemi';
    src: url(../../fonts/Manrope-SemiBold.ttf);
}

@font-face {
    font-family: 'ManLight';
    src: url(../../fonts/Manrope-Light.ttf);
}

@font-face {
    font-family: 'ManMedium';
    src: url(../../fonts/Manrope-Medium.ttf);
}

@font-face {
    font-family: 'ManRegular';
    src: url(../../fonts/Manrope-Regular.ttf);
}

@font-face{
    font-family: 'NunItalic';
    src: url(../../fonts/NunitoSans-Italic.ttf);
}

.feedProfile {
    height: 36px;
    border-radius: 20px;
}

.feedWillPayProfile{
    height: 60px;
    border-radius: 30px;
    margin-right:1.5rem;
}

.feedCard {
    font-family: 'ManSemi';
}

.sliderPriceText{
    font-family: 'NunItalic';
    font-size: 12px;
}

.feedDesc {
    font-family: 'ManMedium';
}

.feedTextLight{
    font-family: 'ManRegular';
}

.feedHighlightText {
    font-family: 'ManSemi';
    background-color: #f8fbcf;
    padding: 0.2rem;
    border-radius: 5px;
}

.TabActive {
    background-color: #6a7bff;
    color: #ffffff;
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border: 0;
    font-family: 'ManMedium';
    width: 232px;
    height: 40px;
}

.TabInactive {
    background-color: #ffffff;
    color: #6a7bff;
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border: 0;
    font-family: 'ManMedium';
    width: 232px;
    height: 40px;
}

.willpay {
    background-color: #41A345;
    color: #ffffff;
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border: 0;
    width: 180px;
    height: 40px;
}

.willPaySelected {
    background-color: #41A345;
    color: #ffffff;
    font-size: 14px;
    box-shadow: inset 0px 3px 15px rgb(0 0 0 / 37%);
    border: 0;
    width: 180px;
    height: 40px;

}

.tags {
    background-color: #efefef;
    font-family: 'ManSemi';
    font-size: 12px;
    padding: 8px;
    margin-right: 12px;
}

.willnot {
    background-color: #DB6D7A;
    color: #ffffff;
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border: 0;
    width: 180px;
    height: 40px;
}

.willnotSelected{
    background-color: #DB6D7A;
    color: #ffffff;
    font-size: 14px;
    box-shadow: inset 0px 3px 15px rgb(0 0 0 / 37%);
    border: 0;
    width: 180px;
    height: 40px; 
}

.homeInput {
    box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.25);
    border: 0;
    border-radius: 0;
    font-size: 15px !important;
    font-family: 'ManRegular';
    background-color: #ECECEC;
    padding-left: 5px;
}

.creatorFont {
    font-family: 'ManRegular';
}

.feedModalHeader {
    font-family: 'ManMedium';
    font-size: 22px;
}

.feedModalDesc {
    font-family: 'ManLight';
    font-size: 16px;
    color: #606060;
}

.feedModalEmailCopy {
    font-size: 12px;
    background-color: #EDEDED;
    padding: 10px;
    border-radius: 18px;
}

.feedEmailLogo {
    width: 18px;
    height: 18px;
}

.feedcreate {
    border: 1px solid #ececec;
    height: 320px;
    color: #999999;
}

.feedLabel {
    font-size: 14px;
    font-family: 'RobotRegular';
}

.sliderCustom {
    margin-top: 30px;
    z-index: 0;
}

.accessSpecifier {
    background: #f4f4f4;
    border-radius: 5px;
    font-size: 14px;
    border: 0;
    padding: 7px;
    color: #8a8a8a;
    font-family: 'RobotLight';
}

.accessSpecifierPrivate {
    background: #C54242;
    border-radius: 5px;
    font-size: 14px;
    border: 0;
    padding: 7px;
    color: #FFFFFF;
    font-family: 'RobotLight';
}

.postedMessage {
    width: 568px;
    height: 420px;
    margin-left: auto;
    margin-top: 60px;
    margin-right: auto;
    font-size: 22px;
    padding: 60px;
    text-align: center;
    font-family: 'RobotLight';
}

.yourFeedbackTitle {
    font-size: 16px;
    font-family: 'ManRegular';
    color: #566a7a;
}

.willPayColor {
    color: rgb(84, 165, 56);
    font-family: 'ManMedium';
}

.willNotColor {
    color: rgb(184, 75, 75);
    font-family: 'ManMedium';
}

.customDisable {
    color: #747474;
}

.loadingEngage {
    color: #6a7bff;
    font-family: 'RobotLight';
    font-size: 14px;
}

.buttonDefault {
    background-color: #6a7bff;
    color: #ffffff;
    font-family: 'ManMedium';
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border: 0;
    width: 230px;
    height: 40px;
}

.buttonCancel {
    background-color: #565761;
    color: #ffffff;
    font-family: 'ManMedium';
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border: 0;
    width: 230px;
    height: 40px;
}

.Message {
    width: 568px;
    height: auto;
    margin-left: auto;
    margin-top: 60px;
    margin-right: auto;
    font-size: 22px;
    padding: 60px;
    text-align: center;
    font-family: 'RobotLight';
}

.statsTitle {
    font-size: 20px;
    margin-left: auto;
    font-family: 'ManRegular';
    margin-bottom: 0;
}

.statsBody {
    font-size: 26px;
    font-family: 'ManMedium';
    margin-bottom: 0;
}

.statsContent {
    font-size: 20px;
    font-family: 'ManMedium';
    margin-bottom: 0;
}

.goBack {
    font-family: 'ManLight';
    color: #8a8a8a;
}

.dashboardCard {
    padding: 0.5rem 1rem 0.5rem 0;
}

.dashboardTitle {
    font-size: 14px;
    font-family: 'ManMedium';
    margin: 0;
}

.dashboardIcon {
    width: 18px;
    margin-right: 5px;
}

.dashboardFigure {
    margin-bottom: 0;
    font-family: 'ManMedium';
}

.dashboardLink {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
    padding: 10px;
    background-color: #f0f0f0;
}

.dashboardActiveLink {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
    padding: 10px;
    background-color: #6a7bff;
}

.profileTitle {
    font-family: 'ManSemi';
}

.profileDesc {
    font-family: 'ManRegular';
}

.profileProgress {
    font-family: 'ManSemi';
    font-size: 12px;
}

.profileButton {
    border-radius: 20px;
    padding-right: 10px;
    padding-left: 0;
    font-size: 14px;
    border: 0;
    color: #6a7bff;
    background-color: #ffffff;
    font-family: 'ManSemi';
}

.profileIcon {
    width: 22px;
}

.grid {
    height: 64px;
    width: 64px;
    cursor: pointer;
    box-shadow: 0 2px 5px 1px #a9b3ff;
    border-radius: 30px;
    margin-right: 2rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.profileCard {
    width: 30rem;
    border:0 !important;
	animation-direction: normal;
	animation-duration: 30s;
	animation-iteration-count: infinite;
	animation-name: colors;
	animation-timing-function: ease;
}

.logoOff{
    transition: ease-in 1s;
    display: none;
}

.logoOn{
    transition: ease-in 1s;
    display: block;
}

@keyframes colors {
    0% {box-shadow: 0px 0px 10px 1px #cce0f7;}
   15% {box-shadow: 0px 0px 6px 2px #cae7ec;}
   30% {box-shadow: 0px 0px 6px 2px #efe6d0;}
   45% {box-shadow: 0px 0px 6px 2px #e5f5c5;}
   60% {box-shadow: 0px 0px 6px 2px #ffd8fe;}
   75% {box-shadow: 0px 0px 6px 2px #f3b9ae;}
   90% {box-shadow: 0px 0px 6px 2px #ece2b6;}
  100% {box-shadow: 0px 0px 6px 2px rgb(207, 250, 205);}
}

.feedContentLayout{
    display:none;
}

.feedContentSecondLayout{
    display: block;
    animation: fade_in_show 0.5s;
}

.homeInput{
    width:390px;
    box-shadow:inset 1px 2px 3px rgba(0, 0, 0, 0.25);
    border:0;
    font-size: 14px;
    background-color: #ECECEC;
    padding-left: 5px;
}

.regular{
    background-color:#6a7bff;
    color:#ffffff;
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border:0;
    width: 130px;
    height: 40px;   
    margin-right:auto;
}

@keyframes fade_in_show {
    0% {
        opacity: 0;
        transform: scale(0)
   }
   100% {
        opacity: 1;
        transform: scale(1)
   }
}

@media screen and (max-width: 1280px) {
    .profileCard {
        width: 30rem;
    }
    .grid{
        width:100%;
    }
}


@media screen and (max-width: 414px) {
    .profileCard {
        width: 100%;
    }
    .grid{
        width:100%;
    }
}

@media screen and (max-width: 375px) {
    .profileCard {
        width: 100%;
    }
    .grid{
        width:100%;
    }
}

@media screen and (max-width: 360px) {
    .profileCard {
        width: 100%;
    }
    .grid{
        width:100%;
    }
}

@media screen and (max-width: 320px) {
    .profileCard {
        width: 100%;
    }
    .grid{
        width:100%;
    }
}


.indigo {
    background-color:  #3F51B5;
    color: #ffffff;
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border: 0;
    width: 180px;
    height: 40px;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 40%;
    border-radius: 5px;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  img {
    border-radius: 5px 5px 0 0;
  }
  
  .container {
    padding: 2px 16px;
  }


.hover:hover {
    color: yellow;
  }
  


  @media screen and (max-width: 580px) {
    .navbar-brand {
        width:100%
    }

}