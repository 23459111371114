@font-face{
    font-family:'NunitoS';
    src:url(../../fonts/NunitoSans-SemiBold.ttf);
}
.menu{
    color:#8a8a8a;
    background: #f4f4f4;
    border-radius: 5px;
    font-size: 14px;
    font-family: 'NunitoS';
}

.avatarProfile:hover{
    cursor:pointer;
}



@media screen and (max-width: 500px) {

    .navbarbrand{
      margin-left: 100px;
    }
  }


  