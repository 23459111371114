@font-face{
    font-family:'ManropeSemi';
    src:url(../fonts/Manrope-SemiBold.ttf);
}

.TabActive{
    background-color:#6a7bff;
    color:#ffffff;
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border:0;
    width: 232px;
    font-family: 'ManropeSemi';
    height: 40px;   
}

.TabInactive{
    background-color:#ffffff;
    color:#6a7bff;
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border:0;
    width: 232px;
    font-family: 'ManropeSemi';
    height: 40px;   
}