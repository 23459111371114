@font-face{
    font-family:'ManSemi';
    src:url(../../fonts/Manrope-SemiBold.ttf);
}
@font-face{
    font-family:'ManLight';
    src:url(../../fonts/Manrope-Light.ttf);
}
@font-face{
    font-family:'ManMedium';
    src:url(../../fonts/Manrope-Medium.ttf);
}
@font-face{
    font-family:'ManRegular';
    src:url(../../fonts/Manrope-Regular.ttf);
}
@font-face{
    font-family:'NunitoRegular';
    src:url(../../fonts/NunitoSans-Regular.ttf);
}
@font-face{
    font-family:'NunitoSemi';
    src:url(../../fonts/NunitoSans-SemiBold.ttf);
}
@font-face{
    font-family:'NunitoLight';
    src:url(../../fonts/NunitoSans-Light.ttf);
}
@font-face{
    font-family:'NunitoBold';
    src:url(../../fonts/NunitoSans-Bold.ttf);
}

.buttonDefault{
    background-color:#6a7bff;
    color:#ffffff;
    font-family: 'ManMedium';
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border:0;
    border-radius:8px;
    margin-top:1rem;
    width: 140px;
    height: 40px;

}

.cardTitle{
    font-size: 24px;
    font-family: 'NunitoSemi';
    margin:0;
}

.cardAnotherTitle{
    font-size: 20px;
    font-family: 'NunitoSemi';
    margin:0;
    color:#29333f;
    padding-left:0.5rem;
}

.cardDesc{
    font-family:'NunitoRegular';
    font-size:18px;
    color:#8a8a8a;
}

.engagementText{
    font-family:'NunitoSemi';
    font-size: 14px;
    color:#777777;
}

.lastActive{
    font-family:'NunitoBold';
    font-size: 16px;
    background: #f0f0f0;
    border-radius: 13px;
    padding:10px;
    color:#434343;
    margin:0;
}

.myCreationsTitle{
    font-family:'NunitoBold';
    font-size: 16px;
    color:#434343;
    margin:0;
    padding: 0 15px 10px;
}

.dateFullFormat{
    font-family: 'NunitoBold';
    font-size: 14px;
    color:#777777;
    margin:0;
}

.gridImage{
    height:52px;
    width: 52px;
    border-radius:10px;
}

.gridImage1{
    height:52px;
    width: 52px;
    border-radius:10px;
}

.spacing{
    padding-top:1.25rem;
}

.grid{
    height:100px;
    width: 100px;
    cursor:pointer;
    box-shadow: 0 2px 5px 1px #a9b3ff;
    border-radius:10px;
    margin-right:2rem;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.createGrid{
    height:100px;
    width: 100px;
    margin-right:2rem;
    background-size:64px 64px;
    background-repeat: no-repeat;
    background-position: center center;
}

.calendar{
    width:100% !important;
    margin-top:1.5rem;
    border:0;
    font-family: 'NunitoSemi';
}

.homeInput{
    box-shadow:inset 1px 2px 3px rgba(0, 0, 0, 0.25);
    border:0;
    border-radius: 0;
    font-size: 14px;
    font-family: 'NunitoSemi';
    background-color: #ECECEC;
    padding-left: 5px;
}

.listItem{
    font-family: 'NunitoSemi';
    width:60%;
}

.backIcon{
    width:28px;
}

.backButton{
    background: #ffffff;
    border:0;
}

.feedbackColor:hover{
    color:#6a7bff;
    cursor: pointer;
}

.profileCard {
    width: 30rem;
}

@media screen and (max-width: 1280px) {
    .profileCard {
        width: 30rem;
    }
    .grid{
        width:100%;
    }
}


@media screen and (max-width: 414px) {
    .profileCard {
        width: 100%;
    }
    .grid{
        width:100%;
    }
}

@media screen and (max-width: 375px) {
    .profileCard {
        width: 100%;
    }
    .grid{
        width:100%;
    }
}

@media screen and (max-width: 360px) {
    .profileCard {
        width: 100%;
    }
    .grid{
        width:100%;
    }
}

@media screen and (max-width: 320px) {
    .profileCard {
        width: 100%;
    }
    .grid{
        width:100%;
    }
}