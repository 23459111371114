
@font-face{
    font-family:'ManMedium';
    src:url(../../fonts/Manrope-Medium.ttf);
}

.landingCard{
    border:0;
    box-shadow: 0px 0px 10px #d0d0d0;
}


.twitterButton{
    padding: 8px 18px;
    background: #ffffff;
    border: 0;
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    font-size:14px;
    font-family:'ManMedium';
}

@media (min-width:768px){
    .landingCard{
        width: 30rem; 
    }
}
