.goBack{
    font-family: 'ManLight';
    color: #8a8a8a;
}

@font-face{
    font-family:'ManSemi';
    src:url(../../fonts/Manrope-SemiBold.ttf);
}
@font-face{
    font-family:'ManLight';
    src:url(../../fonts/Manrope-Light.ttf);
}
@font-face{
    font-family:'ManMedium';
    src:url(../../fonts/Manrope-Medium.ttf);
}
@font-face{
    font-family:'ManRegular';
    src:url(../../fonts/Manrope-Regular.ttf);
}

.statsTitle{
    font-size: 20px;
    margin-left: auto;  
    font-family: 'ManRegular';
    margin-bottom: 0;
    color: #999999;
}

.statsBody{
    font-size: 14px;
    font-family:'ManMedium';
    margin-bottom:0;
}

.statsContent{
    font-size: 14px;
    margin-bottom:0;
}

.statsValue{
    font-family:'ManSemi';
}