


/*! CSS Used from: http://localhost:6065/_next/static/css/e31c91468a074076f9c9.css */
*,:after,:before{box-sizing:border-box;border:0 solid #e5e7eb;}
:after,:before{--tw-content:"";}
hr{height:0;color:inherit;border-top-width:1px;}
a{text-decoration:inherit;}
button{font-family:inherit;font-size:100%;line-height:inherit;color:inherit;margin:0;padding:0;}
button{text-transform:none;}
[type=button],button{-webkit-appearance:button;background-color:transparent;background-image:none;}
:-moz-focusring{outline:auto;}
hr,p{margin:0;}
button{cursor:pointer;}
:disabled{cursor:default;}
img,svg{display:block;vertical-align:middle;}
img{height:auto;}
*,:after,:before{--tw-translate-x:0;--tw-translate-y:0;--tw-rotate:0;--tw-skew-x:0;--tw-skew-y:0;--tw-scale-x:1;--tw-scale-y:1;--tw-scroll-snap-strictness:proximity;--tw-ring-offset-width:0px;--tw-ring-offset-color:#fff;--tw-ring-color:rgb(59 130 246/0.5);--tw-ring-offset-shadow:0 0 #0000;--tw-ring-shadow:0 0 #0000;--tw-shadow:0 0 #0000;--tw-shadow-colored:0 0 #0000;}
.m-4{margin:1rem;}
.mx-auto{margin-left:auto;margin-right:auto;}
.my-3{margin-top:.75rem;margin-bottom:.75rem;}
.mx-4{margin-left:1rem;margin-right:1rem;}
.ml-auto{margin-left:auto;}
.mt-2{margin-top:.5rem;}
.mt-8{margin-top:2rem;}
.mt-4{margin-top:1rem;}
.block{display:block;}
.flex{display:flex;}
.hidden{display:none;}
.h-12{height:3rem;}
.h-1{height:.25rem;}
.w-full{width:100%;}
.w-12{width:3rem;}
.w-3\/4{width:75%;}
.cursor-pointer{cursor:pointer;}
.items-center{align-items:center;}
.justify-center{justify-content:center;}
.justify-between{justify-content:space-between;}
.self-end{align-self:flex-end;}
.self-center{align-self:center;}
.rounded-full{border-radius:9999px;}
.rounded{border-radius:.25rem;}
.border{border-width:1px;}
.border-b-2{border-bottom-width:2px;}
.border-solid{border-style:solid;}
.border-gray-100{--tw-border-opacity:1;border-color:rgb(243 244 246/var(--tw-border-opacity));}
.bg-indigo-500{--tw-bg-opacity:1;background-color:rgb(99 102 241/var(--tw-bg-opacity));}
.bg-gray-100{--tw-bg-opacity:1;background-color:rgb(243 244 246/var(--tw-bg-opacity));}
.bg-green-300{--tw-bg-opacity:1;background-color:rgb(134 239 172/var(--tw-bg-opacity));}
.bg-gray-200{--tw-bg-opacity:1;background-color:rgb(229 231 235/var(--tw-bg-opacity));}
.p-4{padding:1rem;}
.px-2{padding-left:.5rem;padding-right:.5rem;}
.py-2{padding-top:.5rem;padding-bottom:.5rem;}
.px-4{padding-left:1rem;padding-right:1rem;}
.pb-3{padding-bottom:.75rem;}
.pt-4{padding-top:1rem;}
.text-sm{font-size:.875rem;line-height:1.25rem;}
.text-xl{font-size:1.25rem;line-height:1.75rem;}
.text-base{font-size:1rem;line-height:1.5rem;}
.text-lg{font-size:1.125rem;line-height:1.75rem;}
.text-2xl{font-size:1.5rem;line-height:2rem;}
.font-semibold{font-weight:600;}
.font-bold{font-weight:700;}
.text-white{--tw-text-opacity:1;color:rgb(255 255 255/var(--tw-text-opacity));}
.text-gray-500{--tw-text-opacity:1;color:rgb(107 114 128/var(--tw-text-opacity));}
.text-indigo-500{--tw-text-opacity:1;color:rgb(99 102 241/var(--tw-text-opacity));}
.text-blue-400{--tw-text-opacity:1;color:rgb(96 165 250/var(--tw-text-opacity));}
.underline{-webkit-text-decoration-line:underline;text-decoration-line:underline;}
.antialiased{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.shadow{--tw-shadow:0 1px 3px 0 rgb(0 0 0/0.1),0 1px 2px -1px rgb(0 0 0/0.1);--tw-shadow-colored:0 1px 3px 0 var(--tw-shadow-color),0 1px 2px -1px var(--tw-shadow-color);}
.shadow,.shadow-sm{box-shadow:var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);}
.shadow-sm{--tw-shadow:0 1px 2px 0 rgb(0 0 0/0.05);--tw-shadow-colored:0 1px 2px 0 var(--tw-shadow-color);}
.shadow-md{box-shadow:var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);}
.shadow-md{--tw-shadow:0 4px 6px -1px rgb(0 0 0/0.1),0 2px 4px -2px rgb(0 0 0/0.1);--tw-shadow-colored:0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -2px var(--tw-shadow-color);}
.hover\:cursor-pointer:hover{cursor:pointer;}
.hover\:bg-indigo-400:hover{--tw-bg-opacity:1;background-color:rgb(129 140 248/var(--tw-bg-opacity));}
.hover\:bg-indigo-50:hover{--tw-bg-opacity:1;background-color:rgb(238 242 255/var(--tw-bg-opacity));}
.hover\:text-indigo-500:hover{--tw-text-opacity:1;color:rgb(99 102 241/var(--tw-text-opacity));}
.hover\:underline:hover{-webkit-text-decoration-line:underline;text-decoration-line:underline;}
@media (min-width:640px){
.sm\:w-1\/2{width:50%;}
}
@media (min-width:768px){
.md\:w-2\/5{width:40%;}
}
@media (min-width:1024px){
.lg\:w-1\/2{width:50%;}
}
@media (min-width:1280px){
.xl\:w-2\/5{width:40%;}
}
a{color:inherit;text-decoration:none;}
*{box-sizing:border-box;}
/*! CSS Used from: http://localhost:6065/_next/static/css/d3af06b1e8592caf9bdf.css */
.Home_card__2SdtB{color:inherit;text-decoration:none;border-radius:10px;}
/*! CSS Used from: Embedded */
.css-1yxmbwk{display:inline-flex;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;position:relative;box-sizing:border-box;-webkit-tap-highlight-color:transparent;background-color:transparent;outline:0px;border:0px;margin:0px;cursor:pointer;user-select:none;vertical-align:middle;appearance:none;text-decoration:none;text-align:center;flex:0 0 auto;font-size:1.5rem;padding:8px;border-radius:50%;overflow:visible;color:rgba(0, 0, 0, 0.54);transition:background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;}
@media print{
.css-1yxmbwk{-webkit-print-color-adjust:exact;}
}
.css-1yxmbwk:hover{background-color:rgba(0, 0, 0, 0.04);}
@media (hover: none){
.css-1yxmbwk:hover{background-color:transparent;}
}
.css-vubbuv{user-select:none;width:1em;height:1em;display:inline-block;fill:currentcolor;flex-shrink:0;transition:fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;font-size:1.5rem;}
.css-w0pj6f{overflow:hidden;pointer-events:none;position:absolute;z-index:0;inset:0px;border-radius:inherit;}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }


.btn{

    background-color: #04AA6D; /* Green */
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;

  
}


@media screen and (max-width: 600px) {

  #navbar-brand{
    margin-left: 230px;
  }
}
