
.feedModalHeader {
    font-family: 'ManMedium';
    font-size: 22px;
}

.feedModalDesc {
    font-family: 'ManLight';
    font-size: 16px;
    color: #606060;
}

.feedModalEmailCopy {
    font-size: 12px;
    background-color: #EDEDED;
    padding: 10px;
    border-radius: 18px;
}

.feedEmailLogo {
    width: 18px;
    height: 18px;
}

.TabActive {
    background-color: #6a7bff;
    color: #ffffff;
    font-size: 14px;
    box-shadow: 0px 3px 15px rgba(132, 132, 132, 0.37);
    border: 0;
    font-family: 'ManMedium';
    width: 232px;
    height: 40px;
}