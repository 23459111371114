.grid{
    height: 70px;
    width: 70px;
    cursor: pointer;
    box-shadow: 0 2px 5px 1px #a9b3ff;
    border-radius: 10px;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}