@font-face{
    font-family:'NunitoSemi';
    src:url(../fonts/NunitoSans-SemiBold.ttf);
}
@font-face{
    font-family:'NunitoRegular';
    src:url(../fonts/NunitoSans-Regular.ttf);
}

.captureButton{
    background-color:#6a7bff;
    color:#ffffff;
    font-size: 14px;
}

.circularWrapper{
    width: 450px;
    height: 360px;
    display: flex;
}

.circularIconStyle{
    align-self:center;
    margin-left: auto;
    margin-right:auto;
}

.feedbackHeading{
    font-family:'NunitoSemi';
}

.chatList{
    background-color: #bdcdd2;
    padding: 0.5rem;
    margin-left: 4px;
    font-size: 14px;
    border-radius: 10px;
    color: #4a3535;
    font-family: 'NunitoRegular';
}

.timeStamp{
    font-size:12px;
}

.feedbackText{
    margin-bottom:0.5rem;
    font-family: 'NunitoSemi';
}

@media screen and (max-width: 600px) {

    #navbar-brand{
      margin-left: 300px;
    }
  }
  